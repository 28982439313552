import { HistoricalProfileAgeGenderRow } from "@coral/typings";
import { useMemo } from "react";

export default function useAgeGenderAffinity(items: HistoricalProfileAgeGenderRow[]) {
  return useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          const { age, gender, score } = item;
          acc.age.push(age);
          acc.gender.push(gender);
          acc.score.push(score);
          return acc;
        },
        { age: [], gender: [], score: [] } as { age: string[]; gender: string[]; score: string[] },
      ),
    [],
  );
}
