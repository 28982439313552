import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ typography, colors }) => ({
  container: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
  },
  title: {
    fontWeight: typography.fontWeights.semiBold,
    fontSize: typography.h3.fontSize,
    lineHeight: typography.h3.lineHeight,
    margin: 0,
  },
  description: {
    color: colors.gray,
  },
}));

interface SectionTitleProps {
  text: string;
  description?: string;
  className?: string;
}

export default function Title({ description, text, className = "" }: SectionTitleProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)}>
      <h3 className={classes.title}>{text}</h3>
      {description ? <p>{description}</p> : null}
    </div>
  );
}
