import { useParams } from "react-router-dom";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { CampaignPreview } from "@coral/typings";
import SearchPreviewBody from "search-config/pages/search-preview/components/search-preview-body";
import Button from "@gfg/ui-v2/components/button";
import { makeStyles } from "@gfg/ui-v2/theming";
import { useState } from "react";
import PageContainer from "../../../common/components/containers/page-container";
import ContentContainer from "../../../common/components/containers/content-container";
import usePreview from "./hooks/use-preview";
import { isSuccessfulResponse } from "../edit-campaign";
import useExport from "~/campaigns/pages/campaign-preview/hooks/use-export";
import ExportPreviewsDialog from "~/common/components/export-dialog";

const useStyles = makeStyles()(() => ({
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default function CampaignPreviewPage() {
  const { campaignId } = useParams();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  const { configData, previewData, query, uuid, setOffset, setLimit, setQuery, setUuid, offset, loading, limit } =
    usePreview({
      campaignId,
    });

  const [onExport, isExportLoading] = useExport({
    campaignId,
    offset,
    uuid,
    query,
  });

  return (
    <PageContainer>
      <div className={classes.pageHeader}>
        <h4>Merchandising Preview</h4>
        <Button disabled={(!query && !uuid) || !previewData?.previews?.length} onClick={() => setOpen(true)}>
          Export
        </Button>
      </div>

      <ContentContainer>
        {isSuccessfulResponse(configData) && (
          <SearchPreviewBody
            loading={loading}
            configId={campaignId}
            versionId={configData?.value}
            configName={configData?.name}
            onQueryChange={setQuery}
            onUuidChange={setUuid}
            isCampaign
            query={query}
            uuid={uuid}
            searchPreviewItems={previewData?.previews as CampaignPreview[]}
            limit={limit}
            offset={offset}
            onLimitChange={setLimit}
            onOffsetChange={setOffset}
            total={previewData?.total}
          />
        )}
        {!isSuccessfulResponse(configData) && loading && <LinearProgress indeterminate />}
      </ContentContainer>
      <ExportPreviewsDialog
        loading={isExportLoading}
        onExport={onExport}
        filter={{ query, uuid }}
        setOpen={setOpen}
        open={open}
      />
    </PageContainer>
  );
}
