import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  emptyData: {
    color: palette.gray[600],
    padding: spacing("md"),
    textAlign: "center",
  },
}));

export default function NoDataPlaceholder() {
  const { classes } = useStyles();
  return <div className={classes.emptyData}>No data</div>;
}
