import { useCallback, useEffect } from "react";
import debounce from "lodash.debounce";
import { useReactiveVar } from "@apollo/client";
import { useGetUserProfileLazyQuery } from "~/generated";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

export default function useGetUserProfile(userId?: string) {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [getUserProfile, { data, loading, error }] = useGetUserProfileLazyQuery({
    variables: { params: { projectId: selectedEnvironmentProjectId, userId } },
    fetchPolicy: "cache-first",
  });

  const userProfile = data?.userProfile ?? null;

  const fetchUserProfile = useCallback(
    debounce(async params => {
      await getUserProfile({ variables: { params } });
    }, 200),
    [getUserProfile],
  );

  useEffect(() => {
    if (userId) {
      const params = {
        userId,
        projectId: selectedEnvironmentProjectId,
      };
      fetchUserProfile(params);
    }
  }, [selectedEnvironmentProjectId, userId]);

  return {
    loading,
    userId,
    userProfile,
  };
}
