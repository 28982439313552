import { useEffect, useState, useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import debounce from "lodash/debounce";
import { useSearchParams } from "react-router-dom";
import pagination from "../../../../common/constants/pagination";
import reactiveVariables from "../../../../common/graphql/global-reactive-variables";
import { useGetCampaignPreviewLazyQuery } from "../../../../generated";

interface UsePreviewProps {
  campaignId: string;
}

export default function usePreview({ campaignId }: UsePreviewProps) {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(+pagination.LIMITS[pagination.LIMITS.length - 1]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [uuid, setUuid] = useState(searchParams.get("uuid") ?? "");
  const [query, setQuery] = useState(searchParams.get("query") ?? "");

  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [getCampaignPreview, { data, previousData, loading }] = useGetCampaignPreviewLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      previewParams: {
        campaignId,
        projectId: selectedEnvironmentProjectId,
        limit,
        offset,
        query,
        uuid,
      },
      configParams: {
        campaignId,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const previewData = (data ?? previousData)?.campaignPreview;
  const configData = (data ?? previousData)?.campaign;

  const debouncedGetCampaignPreview = useCallback(
    debounce(async variables => {
      await getCampaignPreview({ variables });
    }, 300),
    [getCampaignPreview],
  );

  useEffect(() => {
    debouncedGetCampaignPreview({
      previewParams: {
        campaignId,
        projectId: selectedEnvironmentProjectId,
        limit,
        offset,
        query,
        uuid,
      },
      configParams: {
        campaignId,
        projectId: selectedEnvironmentProjectId,
      },
    });

    return () => {
      debouncedGetCampaignPreview.cancel();
    };
  }, [query, uuid, campaignId, offset, limit, selectedEnvironmentProjectId, debouncedGetCampaignPreview]);

  const handleChangeQuery = useCallback((newQuery: string) => {
    setQuery(newQuery);
    setSearchParams(prev => {
      prev.set("query", newQuery);
      return prev;
    });
  }, []);

  const handleChangeUuid = useCallback((newUuid: string) => {
    setUuid(newUuid);
    setSearchParams(prev => {
      prev.set("uuid", newUuid);
      return prev;
    });
  }, []);

  return {
    configData,
    limit,
    loading,
    offset,
    previewData,
    query,
    uuid,
    setLimit,
    setOffset,
    setQuery: handleChangeQuery,
    setUuid: handleChangeUuid,
  };
}
