import { makeStyles } from "@gfg/ui-v2/theming";
import { Theme } from "@gfg/ui-v2/theming/create-theme";

const useStyles = makeStyles()((theme: Theme) => ({
  sectionHead: {
    paddingBottom: theme.spacing("lg"),
    borderBottom: `${theme.size(1)} solid ${theme.palette.tertiary[200]}`,
    marginBottom: theme.spacing("lg"),
  },
  sectionTitle: {
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    marginBottom: theme.spacing("xs"),
  },
  sectionDescription: {
    fontSize: theme.typography.body.fontSize,
    lineHeight: theme.typography.body.lineHeight,
    color: theme.palette.tertiary[600],
    paddingRight: theme.spacing("xxl"),
    margin: 0,
  },
}));

interface SectionHeaderProps {
  title: string;
  description: string;
}

export default function SectionHeader({ title, description }: SectionHeaderProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.sectionHead}>
      <h3 className={classes.sectionTitle}>{title}</h3>
      <p className={classes.sectionDescription}>{description}</p>
    </div>
  );
}
