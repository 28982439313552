import { makeStyles } from "@gfg/ui-v2/theming";
import { HistoricalProfileAgeGenderRow } from "@coral/typings";
import SingleList, { SingleListItem } from "~/user-profile/components/historical-profile/single-list";
import useAgeGenderAffinity from "~/user-profile/hooks/use-age-gender-affinity";
import TableWrapper from "~/user-profile/components/table-wrapper";
import convertScore from "~/user-profile/utils/cocnvert-score";

const useStyles = makeStyles()(({ spacing }) => ({
  listItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  list: {
    "width": "calc(100% / 3)",
    "paddingLeft": spacing("sm"),
    "paddingRight": spacing("sm"),

    "&:first-of-type": {
      paddingRight: spacing("sm"),
      paddingLeft: 0,
    },
    "&:last-of-type": {
      paddingLeft: spacing("sm"),
      paddingRight: 0,
    },
  },
}));

interface Props {
  items: HistoricalProfileAgeGenderRow[];
}

export default function AgeGenderAffinity({ items }: Props) {
  const { classes } = useStyles();
  const { age, score, gender } = useAgeGenderAffinity(items);

  return (
    <TableWrapper title="Age gender affinity">
      <div className={classes.listItems}>
        <SingleList headerText="Gender" isEmpty={gender.length === 0} className={classes.list}>
          {gender.map(item => (
            <SingleListItem key={`${item}`}>{item}</SingleListItem>
          ))}
        </SingleList>
        <SingleList headerText="Age" isEmpty={age.length === 0} className={classes.list}>
          {age.map(item => (
            <SingleListItem key={`${item}`}>{item}</SingleListItem>
          ))}
        </SingleList>
        <SingleList headerText="Score" isEmpty={score.length === 0} className={classes.list}>
          {score.map(item => (
            <SingleListItem key={`${item}`}>{convertScore(item)}</SingleListItem>
          ))}
        </SingleList>
      </div>
    </TableWrapper>
  );
}
