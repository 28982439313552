import React from "react";
import { Category, Price } from "@coral/typings";
import { makeStyles } from "@gfg/ui-v2/theming";
import { Tag } from "@gfg/ui-v2/components/tag";

import ProductCatalogTable from "./product-catalog-table";
import ProductCatalogRow from "./product-catalog-table/row";
import ProductSectionTitle from "./product-section-title";
import date from "../../common/services/dates";
import ImageWithPlaceholder from "~/common/components/image-with-placeholder";

const useStyles = makeStyles()(({ spacing, colors, palette, typography }) => ({
  container: {
    border: `1px solid ${palette.tertiary[200]}`,
    marginTop: spacing(32),
  },
  tag: {
    width: "fit-content",
    marginLeft: spacing(4),
    margin: spacing(4),
    background: palette.primary[100],
    border: "none",
  },
  previewImage: {
    maxHeight: 400,
    maxWidth: 600,
    paddingLeft: spacing(24),
  },
  previewImageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 400,
    maxWidth: 600,
  },
  productTitle: {
    marginTop: 0,
  },
  name: {
    color: colors.primary,
    fontWeight: typography.fontWeights.bold,
    paddingRight: 15,
  },
  value: {
    textAlign: "right",
  },
  contentContainer: {
    display: "flex",
    padding: spacing(24),
  },
  leftPart: {
    maxWidth: "35%",
  },
}));

export interface ProductCatalogOverviewProps {
  mainCategories: Array<Category>;
  categories: Array<Category>;
  title?: string;
  imageUrl?: string | null;
  skus: string[];
  brandName: string;
  brandId: string;
  url: string;
  id: string;
  groupId: string | undefined;
  categoryNamedPath: string[];
  price: Price;
  publishedAt: number;
  sellerId: string | undefined;
  soldCount: number | undefined;
  stockCount: number;
  availability: number;
  customScore: number;
  sizePopularity?: number;
}
export default function ProductCatalogOverview({
  categories,
  title,
  imageUrl,
  skus,
  brandName,
  brandId,
  url,
  id,
  price,
  publishedAt,
  sellerId,
  stockCount,
  groupId,
  mainCategories,
  soldCount,
  availability,
  categoryNamedPath,
  customScore,
  sizePopularity,
}: ProductCatalogOverviewProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <ProductSectionTitle title={categories.map(category => category.name).join(", ")} />
      <div className={classes.contentContainer}>
        <div className={classes.leftPart}>
          <h4 className={classes.productTitle}>{title}</h4>
          <div className={classes.previewImageWrapper}>
            {imageUrl ? (
              <ImageWithPlaceholder
                className={classes.previewImage}
                src={imageUrl}
                placeholderSrc="/loading-image-400x400.png"
              />
            ) : (
              <img className={classes.previewImage} src="/no-image-400x400.png" alt="product preview" />
            )}
          </div>
        </div>
        <ProductCatalogTable>
          <ProductCatalogRow>
            <span className={classes.name}>Product ID</span>
            <span className={classes.value}>{id}</span>
          </ProductCatalogRow>
          {groupId && (
            <ProductCatalogRow>
              <span className={classes.name}>Group ID</span>
              <span className={classes.value}>{groupId}</span>
            </ProductCatalogRow>
          )}
          <ProductCatalogRow>
            <span className={classes.name}>SKUs</span>
            <span className={classes.value}>
              {skus.map(sku => (
                <Tag className={classes.tag} key={sku} label={sku} variant="outline" />
              ))}
            </span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Brand ID</span>
            <span className={classes.value}>{brandId}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Brand Name</span>
            <span className={classes.value}>{brandName}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Main Category IDs</span>
            <span className={classes.value}>
              {mainCategories.map(category => (
                <Tag key={category.id} className={classes.tag} label={category.id} variant="outline" />
              ))}
            </span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Category IDs</span>
            <span className={classes.value}>
              {categories.map(category => (
                <Tag key={category.id} className={classes.tag} label={category.id} variant="outline" />
              ))}
            </span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Category Named Path</span>
            <span className={classes.value}>
              {categoryNamedPath.map(path => (
                <Tag key={path} className={classes.tag} label={path} variant="outline" />
              ))}
            </span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Current price</span>
            <span className={classes.value}>{price.current}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Currency</span>
            <span className={classes.value}>{price.currency}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Discount</span>
            <span className={classes.value}>{price.discount || "None"}</span>
          </ProductCatalogRow>
          {price.discount ? (
            <ProductCatalogRow>
              <span className={classes.name}>Previous price</span>
              <span className={classes.value}>{price.previous}</span>
            </ProductCatalogRow>
          ) : null}
          <ProductCatalogRow>
            <span className={classes.name}>Availability</span>
            <span className={classes.value}>{availability}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Stock Count</span>
            <span className={classes.value}>{stockCount}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Seller ID</span>
            <span className={classes.value}>{sellerId}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Sold Count</span>
            <span className={classes.value}>{soldCount}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Product URL</span>
            <span className={classes.value}>
              <a href={url} rel="noreferrer" target="_blank">
                {url}
              </a>
            </span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Published At</span>
            <span className={classes.value}>{date.fromSecondsSinceEpoch(publishedAt).toLocaleString()}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Custom Score</span>
            <span className={classes.value}>{customScore}</span>
          </ProductCatalogRow>
          <ProductCatalogRow>
            <span className={classes.name}>Size Popularity</span>
            <span className={classes.value}>{sizePopularity}</span>
          </ProductCatalogRow>
        </ProductCatalogTable>
      </div>
    </div>
  );
}
