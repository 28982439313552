import { makeStyles } from "@gfg/ui-v2/theming";
import { Option, Select } from "@gfg/ui-v2/components/select";
import { SearchInput } from "@gfg/ui-v2/components/input";
import Button from "@gfg/ui-v2/components/button";

export enum UserProfileType {
  // RANDOM = "random",
  SPECIFIC = "specific",
}

export const UserProfileTypeTranslations = {
  [UserProfileType.SPECIFIC]: "Insert user profile ID",
  // [UserProfileType.RANDOM]: "Get random user profile ID",
};

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: spacing("xl"),
  },
  select: {
    "display": "flex",
    "width": "100%",

    "& input": {
      width: "100%",
    },
  },
  searchInput: {
    "display": "flex",
    "width": "100%",

    "& input": {
      width: "100%",
    },
  },
  first: {
    "display": "flex",
    "width": "50%",
    "paddingRight": spacing("xs"),

    "> div": {
      width: "100%",
    },
  },
  second: {
    "display": "flex",
    "width": "50%",
    "paddingLeft": spacing("md"),

    "> div": {
      width: "100%",
    },
  },
}));

interface SelectUserProfileProps {
  setProfileType: (type: UserProfileType) => void;
  profileType?: UserProfileType;
  onSearch: (val: string) => void;
}

export default function SelectUserProfile({ onSearch, setProfileType, profileType }: SelectUserProfileProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.first}>
        <Select
          className={classes.select}
          placeholder="Select user profile ID"
          onChange={setProfileType}
          value={profileType}
        >
          {Object.values(UserProfileType).map(key => (
            <Option value={key} text={UserProfileTypeTranslations[key]} />
          ))}
        </Select>
      </div>
      <div className={classes.second}>
        {profileType === UserProfileType.SPECIFIC ? (
          <SearchInput
            onChange={onSearch}
            className={classes.searchInput}
            type="text"
            placeholder="Enter user profile ID"
          />
        ) : (
          <Button>Get ID</Button>
        )}
      </div>
    </div>
  );
}
