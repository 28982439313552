import { AlertCircleIcon, BookOpenIcon, SearchIcon, TextFileIcon, ThumbsUpIcon, UsersIcon } from "@gfg/ui-v2/icons";
import { useMemo } from "react";
import useViewer from "auth/hooks/use-viewer";
import { ResourceName } from "@coral/typings";
import { MenuItemConfig } from "../../constants/menu-config-list";
import useCurrentSelectedEnvironment from "~/common/hooks/use-current-environment";

function filterDeniedSections(allowedResourceNames: ResourceName[], items: MenuItemConfig[]) {
  return items.filter(menuItem => {
    // Check if the menu item has a valid resourceName and that the user has access to it.
    if (menuItem.resourceName && !allowedResourceNames.includes(menuItem.resourceName)) {
      return false;
    }

    // If the menu item has subcategories, filter out the ones the user does not have access to.
    if (menuItem.subcategories) {
      menuItem.subcategories = menuItem.subcategories.filter(subcategory =>
        subcategory.resourceName ? allowedResourceNames.includes(subcategory.resourceName) : true,
      );
    }

    // If there is no subcategories but they were, remove the menu item.
    if (menuItem.subcategories && menuItem.subcategories.length === 0) {
      return false;
    }

    // If none of the previous conditions were met, the user has access to the menu item.
    return true;
  });
}

function applySectionsWhitelist(currentEnv: string, items: MenuItemConfig[]) {
  return items.filter(menuItem => {
    if (menuItem.regionWhitelist && !menuItem.regionWhitelist.includes(currentEnv)) {
      return false;
    }

    if (menuItem.subcategories) {
      menuItem.subcategories = menuItem.subcategories.filter(
        subcategory => !subcategory.regionWhitelist || subcategory.regionWhitelist.includes(currentEnv),
      );
    }

    return true;
  });
}

const menuList: MenuItemConfig[] = [
  {
    id: "search",
    text: "Search",
    // linkTo: "/search",
    enabled: true,
    leftIcon: SearchIcon,
    parent: false,
    subcategories: [
      {
        id: "search-config",
        text: "Search Configurations",
        linkTo: `/search/search-config`,
        parent: true,
        resourceName: "search_configs",
      },
      {
        id: "search-synonyms",
        text: "Synonyms",
        linkTo: `/search/synonyms`,
        parent: true,
        resourceName: "synonyms",
      },
      {
        id: "search-synonyms-unidirectional",
        text: "Unidirectional Synonyms",
        linkTo: `/search/unidirectional-synonyms`,
        parent: true,
        resourceName: "synonyms",
      },
      {
        id: "search-optimizations",
        text: "Search Optimization",
        linkTo: `/search/optimizations`,
        parent: true,
        resourceName: "search_rules",
      },
      {
        id: "search-analytics",
        text: "Analytics",
        linkTo: `/search/analytics`,
        parent: true,
        resourceName: "analytics_search_config",
        regionWhitelist: [
          "the_iconic_au",
          "zalora_sg",
          "zalora_my",
          "zalora_ph",
          "zalora_hk",
          "zalora_id",
          "zalora_tw",
        ],
      },
      {
        id: "campaigns",
        text: "Merchandising",
        linkTo: `/merchandising`,
        parent: true,
        resourceName: "campaigns",
      },
    ],
  },
  {
    id: "recommendations",
    text: "Recommendations",
    enabled: true,
    leftIcon: ThumbsUpIcon,
    parent: false,
    subcategories: [
      {
        id: "recommendations-feed",
        text: "Feed",
        linkTo: `/recommendations/feed`,
        parent: true,
        resourceName: "feeds",
      },
      {
        id: "recommendations-newsletter",
        text: "Newsletter",
        linkTo: `/recommendations/newsletter`,
        parent: true,
        resourceName: "newsletters",
      },
      {
        id: "recommendations-analytics",
        text: "Analytics",
        linkTo: `/recommendations/analytics`,
        parent: true,
        resourceName: "analytics_recommendation",
        regionWhitelist: ["the_iconic_au"],
      },
    ],
  },
  {
    id: "user_profile",
    text: "User profile",
    linkTo: `/user-profile`,
    enabled: true,
    leftIcon: UsersIcon,
    parent: false,
    resourceName: "user_profile",
  },
  {
    id: "product-catalog",
    text: "Product Catalog",
    linkTo: `/product-catalog`,
    enabled: true,
    leftIcon: BookOpenIcon,
    parent: false,
    resourceName: "product_catalog",
  },
  {
    id: "admin",
    text: "Admin",
    linkTo: `/admin`,
    enabled: true,
    leftIcon: UsersIcon,
    parent: false,
    admin: true,
    resourceName: "admin",
  },
  {
    id: "documentation",
    text: "Documentation",
    linkTo: `/docs`,
    external: true,
    enabled: true,
    leftIcon: TextFileIcon,
    parent: false,
  },
  {
    id: "support",
    text: "Report an error",
    linkTo: "https://gfgroup.atlassian.net/servicedesk/customer/portal/4",
    enabled: true,
    leftIcon: AlertCircleIcon,
    parent: false,
    bottom: true,
    external: true,
  },
];

const useMenuConfigList = () => {
  const { viewer } = useViewer();
  const currentSelectedEnvironment = useCurrentSelectedEnvironment();

  const menuSectionsList: MenuItemConfig[] = useMemo(() => {
    const allowedResourceNames = viewer?.resources.map(r => r.name) || [];

    if (allowedResourceNames?.length && currentSelectedEnvironment) {
      return applySectionsWhitelist(
        currentSelectedEnvironment.key,
        filterDeniedSections(allowedResourceNames, menuList),
      );
    }

    return [];
  }, [viewer, currentSelectedEnvironment, menuList]);

  return menuSectionsList;
};

export default useMenuConfigList;
