import { DndContext, closestCenter, DragOverlay, DragStartEvent, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, rectSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconButton } from "@gfg/ui-v2/components/button";
import { InputField } from "@gfg/ui-v2/components/input";
import CreateButton from "common/components/create-button";
import { List, ListItem } from "@gfg/ui-v2/components/list";
import Tag, { TagGroup } from "@gfg/ui-v2/components/tag";
import { DragMoveIcon, Trash2Icon } from "@gfg/ui-v2/icons";
import { Link, useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import { makeStyles } from "@gfg/ui-v2/theming";
import uniq from "lodash/uniq";
import DragAndDropIcon from "~/common/icons/drag-and-drop-icon";

interface HardBoostingInputProps {
  skus: string[];
  onChange(skus: string[]): void;
}

const useStyles = makeStyles()(({ spacing, shadow }) => ({
  tag: {
    width: "fit-content",
    marginLeft: spacing(4),
    marginTop: spacing(10),
    border: "none",
  },
  tagLink: {
    textDecoration: "none",
  },
  tagTouchZone: {
    cursor: "pointer",
  },
  skuItem: {
    position: "relative",
    boxShadow: shadow("md"),
    margin: spacing(20, 1),
    borderRadius: "4px",
    padding: spacing(16, 16),
  },
  inputContainer: {
    "display": "flex",
    "flexDirection": "row",
    "gap": spacing(20),
    "& input": {
      width: spacing(512),
    },
    "& textarea": {
      width: spacing(512),
    },
  },
}));

interface SortableItemProps {
  sku: string;
  handleRemoveSku(sku: string): void;
}

function SortableItem({ sku, handleRemoveSku }: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: sku });
  const { classes } = useStyles();
  const { region } = useParams();
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 100 : "auto",
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <TagGroup ref={setNodeRef} size="small" style={style} className={classes.tag}>
      <Tag
        key={sku}
        closable
        label={
          <Link
            target="_blank"
            to={{ pathname: `/${region}/product-catalog`, search: `?q=${sku}` }}
            className={classes.tagLink}
          >
            {sku}
          </Link>
        }
        variant="faded"
        size="small"
        onClose={() => handleRemoveSku(sku)}
      />
      <Tag className={classes.tagTouchZone} {...attributes} {...listeners} size="small" icon={<DragAndDropIcon />} />
    </TagGroup>
  );
}

export default function HardBoostingInput({ skus, onChange }: HardBoostingInputProps) {
  const { classes } = useStyles();
  const [skuInput, setSkuInput] = useState("");
  const [activeId, setActiveId] = useState(null);

  const handleAddSkus = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      const skusToAdd = skuInput
        .split(",")
        .map(sku => sku.trim())
        .filter(s => !!s);

      const skusCombined = [...skus, ...skusToAdd];

      const uniqueSkus = uniq(skusCombined);
      onChange(uniqueSkus);
      setSkuInput("");
    },
    [skuInput, skus, onChange],
  );

  const handleRemoveSku = useCallback(
    (sku: string) => {
      const skusFiltered = skus.filter(s => s !== sku);
      onChange(skusFiltered);
    },
    [skus, onChange],
  );

  const handleRemoveAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over.id) {
        const oldIndex = skus.indexOf(active.id as string);
        const newIndex = skus.indexOf(over.id as string);
        onChange(arrayMove(skus, oldIndex, newIndex));
      }
    },
    [skus, onChange],
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setActiveId(event.active.id);
    },
    [setActiveId],
  );

  return (
    <div>
      <div className={classes.inputContainer}>
        <InputField
          rows={2}
          multiline
          placeholder="Type SKUs"
          hint="Enter SKUs comma-separated, e.g. BR319APF44OJF, BR319APF44OXLL"
          value={skuInput}
          onChange={setSkuInput}
        />
        <CreateButton text="Add SKUs" onClick={handleAddSkus} />
      </div>
      {skus.length > 0 && (
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <SortableContext items={skus} strategy={rectSortingStrategy}>
            <List>
              <ListItem
                rightIcon={<IconButton onClick={handleRemoveAll} icon={Trash2Icon} round variant="faded" />}
                className={classes.skuItem}
              >
                {skus.map(sku => (
                  <SortableItem key={sku} sku={sku} handleRemoveSku={handleRemoveSku} />
                ))}
                <DragOverlay adjustScale>
                  {activeId ? (
                    <TagGroup className={classes.tag}>
                      <Tag key="overlay" label={activeId} variant="faded" size="small" />
                      <Tag className={classes.tagTouchZone} size="small" icon={<DragAndDropIcon />} />
                    </TagGroup>
                  ) : null}
                </DragOverlay>
              </ListItem>
            </List>
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
}
