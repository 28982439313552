import { makeStyles } from "@gfg/ui-v2/theming";
import { useCallback, useState } from "react";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import Title from "~/common/components/title";
import SelectUserProfile, { UserProfileType } from "~/user-profile/components/select-user-profile";
import Profile from "~/user-profile/components/profile";
import RealtimeList from "~/user-profile/components/realtime-profile";
import HistoricalProfile from "~/user-profile/components/historical-profile";
import useGetUserProfile from "~/user-profile/hooks/use-get-user-profile";
import debounce from "lodash/debounce";

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    margin: spacing(20, 16),
  },
  userProfileWrapper: {
    paddingTop: spacing("lg"),
  },
}));

export default function UserProfilePage() {
  const { classes } = useStyles();
  const [userProfileType, setProfileType] = useState<UserProfileType | undefined>(UserProfileType.SPECIFIC);
  const [userId, setUserId] = useState<string | undefined>();

  const handleChangeUserId = useCallback(
    debounce((value: string) => {
      setUserId(value);
    }, 100),
    [],
  );

  const { userProfile, loading } = useGetUserProfile(userId);

  return (
    <div className={classes.container}>
      <Title
        text="User profile"
        description="In this section, you will find the user preferences currently in use for personalization in Datajet."
      />

      <div className={classes.userProfileWrapper}>
        <SelectUserProfile
          onSearch={handleChangeUserId}
          profileType={userProfileType}
          setProfileType={setProfileType}
        />
        {loading ? <LinearProgress indeterminate /> : null}
        {userProfile && !loading ? (
          <>
            <Profile mainProfile={userProfile?.mainProfileInfo} />
            <RealtimeList
              recentlyViewedBrands={userProfile.inSessionProfile.recentlyViewedBrands}
              recentlyViewedCategories={userProfile.inSessionProfile.recentlyViewedCategories}
            />
            <HistoricalProfile historicalProfile={userProfile?.historicalProfile} />
          </>
        ) : null}
      </div>
    </div>
  );
}
