import { List, ListItem } from "@gfg/ui-v2/components/list";
import { makeStyles } from "@gfg/ui-v2/theming";
import { ProfileRow } from "@coral/typings";
import NoDataPlaceholder from "~/user-profile/components/no-data-placeholder";
import convertScore from "~/user-profile/utils/cocnvert-score";

const useStyles = makeStyles()(({ size, spacing, palette, typography }) => ({
  listItem: {
    "width": "100%",

    "&>div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  borderedListItem: {
    color: palette.gray[600],
    borderBottom: `${size(1)} solid ${palette.gray[200]}`,
  },
  listHeader: {
    backgroundColor: palette.gray[50],
    borderRadius: size(8),
    fontWeight: typography.fontWeights.semiBold,
    marginBottom: spacing("xs"),
  },
  leftPart: {
    width: "70%",
  },
  rightPart: {
    width: "30%",
  },
}));

interface RealtimeListProps {
  headerText: string;
  items: ProfileRow[];
  className?: string;
}

export default function RealtimeList({ className, headerText, items = [] }: RealtimeListProps) {
  const { classes, cx } = useStyles();

  return (
    <List className={className}>
      <ListItem className={cx(classes.listHeader, classes.listItem)}>
        <span className={classes.leftPart}> {headerText}</span>
        <span className={classes.rightPart}>Score</span>
      </ListItem>
      {items?.length ? (
        items.map(item => (
          <ListItem key={`${headerText}-${item.value}`} className={cx(classes.listItem, classes.borderedListItem)}>
            <span className={classes.leftPart}>{item.value}</span>
            <span className={classes.rightPart}>{convertScore(item.score)}</span>
          </ListItem>
        ))
      ) : (
        <NoDataPlaceholder />
      )}
    </List>
  );
}
