import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, size, palette, colors, shadow, typography }) => ({
  table: {
    padding: spacing("md"),
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    backgroundColor: colors.background,
    border: `${size(1)} solid  ${palette.tertiary[200]}`,
    borderRadius: size(4),
    boxShadow: shadow("md"),
    marginBottom: spacing("xl"),
  },
  tableTitle: {
    color: colors.primary,
    fontSize: typography.h5.fontSize,
    lineHeight: typography.h5.lineHeight,
    margin: 0,
    paddingBottom: spacing("md"),
    marginBottom: spacing("md"),
    borderBottom: `${size(1)} solid  ${palette.gray[200]}`,
  },
}));

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function TableWrapper({ title, children }: Props) {
  const { classes } = useStyles();

  return (
    <div className={classes.table}>
      <h5 className={classes.tableTitle}>{title}</h5>
      {children}
    </div>
  );
}
