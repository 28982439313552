import { useState } from "react";
import { HistoricalFavoriteCategoryPriceRange } from "@coral/typings";

export default function useFavoriteCategoryPriceRanges(items: HistoricalFavoriteCategoryPriceRange[]) {
  const [selectedCategory, setSelectedCategory] = useState(items[0]?.category || "");

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
  };

  const selectedCategoryItem = items.find(item => item.category === selectedCategory);

  const categories = items.map(item => item.category);

  return {
    categories,
    range:
      selectedCategoryItem.from && selectedCategoryItem.to
        ? [
            {
              from: selectedCategoryItem.from,
              to: selectedCategoryItem.to,
            },
          ]
        : [],
    score: selectedCategoryItem?.score ? [selectedCategoryItem?.score] : [],
    handleCategorySelect,
    selectedCategory: selectedCategoryItem.category,
  };
}
