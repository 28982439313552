import React from "react";
import { IconProps } from "@gfg/ui-v2/icons";

const UserIcon = React.forwardRef<SVGSVGElement, IconProps>(() => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="25" fill="#E9F1F7" />
    <path
      d="M25.0996 42C15.6996 42 8.09961 34.4 8.09961 25C8.09961 15.6 15.6996 8 25.0996 8C34.4996 8 42.0996 15.6 42.0996 25C42.0996 34.4 34.3996 42 25.0996 42ZM25.0996 10C16.7996 10 10.0996 16.7 10.0996 25C10.0996 33.3 16.7996 40 25.0996 40C33.3996 40 40.0996 33.3 40.0996 25C40.0996 16.7 33.2996 10 25.0996 10Z"
      fill="#216FB3"
    />
    <path
      d="M15.3 37.3L13.5 36.5C14 35.3 15.6 34.6 17.3 33.8C19 33 21.1 32.1 21.1 31V29.5C20.5 29 19.5 27.9 19.3 26.3C18.8 25.8 18 24.9 18 23.7C18 23 18.3 22.4 18.5 22C18.3 21.2 18.1 19.7 18.1 18.5C18.1 14.6 20.8 12 25.1 12C26.3 12 27.8 12.3 28.6 13.2C30.5 13.6 32.1 15.8 32.1 18.5C32.1 20.2 31.8 21.6 31.6 22.3C31.8 22.6 32 23.1 32 23.7C32 25 31.3 25.9 30.7 26.3C30.5 27.9 29.6 28.9 29 29.4V31C29 31.9 30.8 32.6 32.4 33.2C34.3 33.9 36.3 34.7 37 36.3L35.1 37C34.8 36.2 33.2 35.6 31.7 35.1C29.5 34.3 27 33.4 27 31.1V28.5L27.5 28.2C27.5 28.2 28.7 27.4 28.7 25.8V25.1L29.3 24.8C29.4 24.8 29.9 24.5 29.9 23.7C29.9 23.5 29.7 23.2 29.6 23.1L29.2 22.7L29.4 22.2C29.4 22.2 29.9 20.6 29.9 18.6C29.9 16.7 28.8 15.3 27.9 15.3H27.3L27 14.8C27 14.4 26.3 14 25.1 14C22 14 20.1 15.7 20.1 18.5C20.1 19.8 20.6 22 20.6 22L20.7 22.5L20.3 23C20.2 23 20 23.3 20 23.7C20 24.2 20.6 24.8 20.9 25L21.3 25.3V25.8C21.3 27.3 22.6 28.1 22.6 28.2L23.1 28.5V31.1C23.1 33.5 20.5 34.7 18.1 35.7C17 36.1 15.5 36.8 15.3 37.3Z"
      fill="#216FB3"
    />
  </svg>
));

export default UserIcon;
