import "swiper/css/bundle";

import { useEffect } from "react";
import { Route, Routes, createRoutesFromElements, useNavigate, useParams, Outlet } from "react-router-dom";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import DuplicateCampaignPage from "campaigns/pages/duplicate-campaign";
import CampaignPreviewPage from "campaigns/pages/campaign-preview";
import useDefaultPage from "common/layout/sidebar-menu/use-default-page";
import useDefaultRegion from "common/layout/sidebar-menu/use-default-region";
import AdminPage from "./admin";
import LogoutPage from "./auth/pages/logout-page";
import LoginPage from "./auth/pages/login-page";
import NewsletterPage from "./newsletter";
import SearchConfigPage from "./search-config/pages/search-configs";
import CreateSearchConfigPage from "./search-config/pages/create-configuration";
import DuplicateSearchConfigPage from "./search-config/pages/duplicate-configuration";
import CreateSearchConfigVersionPage from "./search-config/pages/create-configuration-version";
import EditSearchConfigVersionPage from "./search-config/pages/edit-configuration-version";
import DuplicateSearchConfigVersionPage from "./search-config/pages/duplicate-configuration-version";
import ConfigurationVersionsPage from "./search-config/pages/search-config-versions";
import RequireAuth from "./auth/require-auth";
import NotFound from "./common/pages/not-found-page";
import ErrorUserNotRegistered from "./common/pages/error-user-not-registered";
import Layout from "./common/layout";
import CreateNewsletterPage from "./newsletter/components/newsletter-create/create-newsletter-page";
import EditNewsletterPage from "./newsletter/components/newsletter-edit/edit-newsletter-page";
import DuplicateNewsletterPage from "./newsletter/components/newsletter-duplicate/duplicate-newsletter-page";
import FeedsPage from "./feed";
import CreateFeedPage from "./feed/components/feeds-create/create-feed-page";
import EditFeedPage from "./feed/components/feeds-edit/edit-feed-page";
import DuplicateFeedPage from "./feed/components/feeds-duplicate/duplicate-feed-page";
import ProductCatalogPage from "./product-catalog/pages";
import SearchPreviewPage from "./search-config/pages/search-preview";
import CampaignListPage from "./campaigns/pages/campaign-list";
import CreateCampaignPage from "./campaigns/pages/create-campaign";
import EditCampaignPage from "./campaigns/pages/edit-campaign";
import SynonymsPage from "./synonyms";
import useAppVersion from "./common/hooks/use-app-version";
import useDatadog from "./common/services/datadog/use-datadog";
import { useGetEnvironmentsQuery } from "./generated";
import useViewer from "./auth/hooks/use-viewer";
import SearchAnalyticsPage from "./search-analytics/pages/analytics";
import RecosAnalyticsPage from "./recos-analytics/pages/analytics";
import OptimizationsPageList from "~/search-optimizations/pages/search-optimizations-list";
import CreateSearchOptimizationPage from "~/search-optimizations/pages/create-search-optimization";
import EditSearchOptimizationPage from "~/search-optimizations/pages/edit-search-optimization";
import UnidirectionalSynonymsPage from "~/unidirectional-synonyms";
import FeedPreviewPage from "~/feed/components/feed-preview";
import UserProfilePage from "~/user-profile";

function IndexPage() {
  const navigate = useNavigate();
  const { viewer } = useViewer();
  const { region } = useParams();
  const defaultPage = useDefaultPage();
  const defaultRegion = useDefaultRegion();

  useEffect(() => {
    if (defaultRegion) {
      navigate(`/${defaultRegion}${defaultPage || ""}`, { replace: true });
    }
  }, [defaultRegion, defaultPage, region]);

  if (viewer?.environments && !viewer?.environments?.length) {
    return <h2>No environment found</h2>;
  }

  return <LinearProgress indeterminate />;
}
function RegionValidator() {
  const { region } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useGetEnvironmentsQuery();

  const isRegionInvalid = region && !loading && !data?.configuration?.environments?.some(e => e.key === region);

  useEffect(() => {
    if (isRegionInvalid) {
      navigate("/", { replace: true });
    }
  }, [isRegionInvalid, navigate]);

  if (loading) {
    return <LinearProgress indeterminate />;
  }

  return <Outlet />;
}

function RegionIndexPage() {
  const { region } = useParams();
  const defaultPage = useDefaultPage();
  const navigate = useNavigate();

  useEffect(() => {
    if (defaultPage) {
      navigate(`/${region}${defaultPage}`, { replace: true });
    }
  }, [region, defaultPage]);

  return <LinearProgress indeterminate />;
}

const AppRoutes = (
  <>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/logout" element={<LogoutPage />} />
    <Route
      path="/"
      element={
        <RequireAuth>
          <Layout />
        </RequireAuth>
      }
    >
      <Route path="/" element={<IndexPage />} />
      <Route path="/:region" element={<RegionValidator />}>
        <Route path="/:region" index element={<RegionIndexPage />} />
        <Route path="/:region/search/search-config" element={<RequireAuth section="search_configs" />}>
          <Route path="/:region/search/search-config" index element={<SearchConfigPage />} />
          <Route path=":configId/versions" element={<ConfigurationVersionsPage />} />
          <Route path=":configId/versions/create" element={<CreateSearchConfigVersionPage />} />
          <Route path=":configId/versions/edit/:versionId" element={<EditSearchConfigVersionPage />} />
          <Route path=":configId/versions/preview/:versionId" element={<SearchPreviewPage />} />
          <Route path=":configId/versions/duplicate/:versionId" element={<DuplicateSearchConfigVersionPage />} />
          <Route path="create" element={<CreateSearchConfigPage />} />
          <Route path="duplicate/:configId" element={<DuplicateSearchConfigPage />} />
        </Route>
        <Route path="/:region/search/analytics" element={<RequireAuth section="analytics_search_config" />}>
          <Route path="/:region/search/analytics" index element={<SearchAnalyticsPage />} />
        </Route>
        <Route path="/:region/search/optimizations" element={<RequireAuth section="search_rules" />}>
          <Route path="/:region/search/optimizations" index element={<OptimizationsPageList />} />
          <Route path="create" element={<CreateSearchOptimizationPage />} />
          <Route path="edit/:optimizationId" element={<EditSearchOptimizationPage />} />
        </Route>
        <Route path="/:region/search/synonyms" element={<RequireAuth section="synonyms" />}>
          <Route path="/:region/search/synonyms" index element={<SynonymsPage />} />
        </Route>
        <Route path="/:region/search/unidirectional-synonyms" element={<RequireAuth section="synonyms" />}>
          <Route path="/:region/search/unidirectional-synonyms" index element={<UnidirectionalSynonymsPage />} />
        </Route>
        <Route path="/:region/recommendations/feed" element={<RequireAuth section="feeds" />}>
          <Route path="/:region/recommendations/feed" index element={<FeedsPage />} />
          <Route path="create" element={<CreateFeedPage />} />
          <Route path="edit/:feedId" element={<EditFeedPage />} />
          <Route path="edit/:feedId/preview" element={<FeedPreviewPage />} />
          <Route path="duplicate/:feedId" element={<DuplicateFeedPage />} />
        </Route>
        <Route path="/:region/recommendations/newsletter" element={<RequireAuth section="newsletters" />}>
          <Route path="/:region/recommendations/newsletter" index element={<NewsletterPage />} />
          <Route path="edit/:newsletterId" element={<EditNewsletterPage />} />
          <Route path="duplicate/:newsletterId" element={<DuplicateNewsletterPage />} />
          <Route path="create" element={<CreateNewsletterPage />} />
          <Route path="edit/:newsletterId/preview" element={<FeedPreviewPage />} />
        </Route>
        <Route path="/:region/recommendations/analytics" element={<RequireAuth section="analytics_recommendation" />}>
          <Route path="/:region/recommendations/analytics" index element={<RecosAnalyticsPage />} />
        </Route>
        <Route path="/:region/user-profile" element={<RequireAuth section="user_profile" />}>
          <Route path="/:region/user-profile" index element={<UserProfilePage />} />
        </Route>
        <Route path="/:region/product-catalog" element={<RequireAuth section="product_catalog" />}>
          <Route path="/:region/product-catalog" index element={<ProductCatalogPage />} />
        </Route>
        <Route path="/:region/merchandising" element={<RequireAuth section="campaigns" />}>
          <Route path="/:region/merchandising" index element={<CampaignListPage />} />
          <Route path="create" element={<CreateCampaignPage />} />
          <Route path="edit/:campaignId" element={<EditCampaignPage />} />
          <Route path="duplicate/:campaignId" element={<DuplicateCampaignPage />} />
          <Route path="preview/:campaignId" element={<CampaignPreviewPage />} />
        </Route>
        <Route path="/:region/admin" element={<RequireAuth section="admin" roles={["master", "regional_manager"]} />}>
          <Route path="/:region/admin" index element={<AdminPage />} />
        </Route>
      </Route>
    </Route>
    <Route path="/user-not-found" element={<ErrorUserNotRegistered />} />
    <Route path="*" element={<NotFound />} />
  </>
);

export const routes = createRoutesFromElements(AppRoutes);

export default function App() {
  useAppVersion();
  useDatadog();

  return <Routes>{AppRoutes}</Routes>;
}
