import { CampaignPreview, SearchConfigPreview } from "@coral/typings";
import { useReactiveVar } from "@apollo/client";
import useDownloadCsv from "~/common/hooks/use-download-csv";
import { useGetCampaignPreviewLazyQuery } from "~/generated";
import reactiveVariables from "~/common/graphql/global-reactive-variables";
import makePreviewRows from "~/search-config/pages/search-preview/utils/make-preview-rows";

interface UsePreviewProps {
  campaignId: string;
  offset?: number;
  uuid?: string;
  query?: string;
}

export default function useExport({ campaignId, offset }: UsePreviewProps) {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const downloadCsv = useDownloadCsv();
  const [getSearchPreview, { loading }] = useGetCampaignPreviewLazyQuery();

  const handleExport = async (variables: { query?: string; uuid?: string }, whatToExport: string) => {
    const response = await getSearchPreview({
      fetchPolicy: "network-only",
      variables: {
        previewParams: {
          campaignId,
          projectId: selectedEnvironmentProjectId,
          limit: 100,
          offset,
          query: whatToExport === "current-filter" ? variables?.query : "",
          uuid: whatToExport === "current-filter" ? variables?.uuid : "",
        },
        configParams: {
          campaignId,
          projectId: selectedEnvironmentProjectId,
        },
      },
    });

    if (response.data) {
      const previews = response?.data?.campaignPreview?.previews || [];

      const convertedPreviews =
        previews?.map(item => {
          const previewRows = makePreviewRows(item as CampaignPreview, true, true);
          return previewRows?.reduce(
            (acc, { name, value }) => (name === "__typename" ? acc : { ...acc, [name]: value }),
            {} as Record<keyof Omit<SearchConfigPreview | CampaignPreview, "__typename">, string>,
          );
        }) || [];

      if (convertedPreviews?.length) {
        const headers = Object.keys(convertedPreviews?.[0]) as (keyof Omit<
          SearchConfigPreview | CampaignPreview,
          "__typename"
        >)[];

        // Prepare data for export
        const dataForExport = convertedPreviews.map(item => headers.map(header => item[header]));
        dataForExport.unshift(headers);

        return downloadCsv(dataForExport, "search-preview");
      }
    }

    return null;
  };

  return [handleExport, loading] as [typeof handleExport, boolean];
}
