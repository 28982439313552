import { List, ListGroupProps, ListItem, ListItemProps } from "@gfg/ui-v2/components/list";
import { makeStyles } from "@gfg/ui-v2/theming";
import { ChevronRightIcon } from "@gfg/ui-v2/icons";
import { ElementOrArray } from "@gfg/ui-v2/types";
import { JSXElementConstructor, ReactElement } from "react";
import NoDataPlaceholder from "~/user-profile/components/no-data-placeholder";

const useStyles = makeStyles()(({ size, colors, spacing, palette, typography }) => ({
  listItem: {
    width: "100%",
  },
  selectableListItem: {
    cursor: "pointer",
    backgroundColor: colors.background,
  },
  borderedListItem: {
    color: palette.gray[600],
    borderBottom: `${size(1)} solid ${palette.gray[200]}`,
  },
  listHeader: {
    backgroundColor: palette.gray[50],
    borderRadius: size(8),
    fontWeight: typography.fontWeights.semiBold,
    marginBottom: spacing("sm"),
  },
  leftPart: {
    width: "75%",
  },
  rightPart: {
    width: "25%",
  },
  active: {
    backgroundColor: palette.primary[100],
    color: colors.primary,
  },
}));

interface ListProps {
  headerText: string;
  className?: string;
  children: ElementOrArray<false | ReactElement<ListItemProps | ListGroupProps, string | JSXElementConstructor<any>>>;
  isEmpty?: boolean;
}

export const SingleListItem = ({ children }: any) => {
  const { classes, cx } = useStyles();

  return <ListItem className={cx(classes.listItem, classes.borderedListItem)}>{children}</ListItem>;
};

export const SelectableListItem = ({ children, onClick, className, active, ...props }: any) => {
  const { classes, cx } = useStyles();

  return (
    <ListItem
      hoverable
      rightIcon={ChevronRightIcon}
      className={cx(classes.listItem, classes.borderedListItem, classes.selectableListItem, active && classes.active)}
      onClick={onClick}
      {...props}
    >
      {children}
    </ListItem>
  );
};

export default function SingleList({ className, headerText, isEmpty, children }: ListProps) {
  const { classes, cx } = useStyles();

  return (
    <List className={className}>
      <ListItem className={cx(classes.listHeader, classes.listItem)}>
        <span className={classes.leftPart}>{headerText}</span>
      </ListItem>
      {!isEmpty ? children : <NoDataPlaceholder />}
    </List>
  );
}
