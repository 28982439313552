import { makeStyles } from "@gfg/ui-v2/theming";
import React from "react";

const useStyles = makeStyles()(({ spacing, colors, palette, typography, size }) => ({
  row: {
    "display": "grid",
    "gridTemplateColumns": "repeat(4, 1fr)",

    "&:first-child": {
      borderTop: `${size(1)} solid ${palette.gray[200]}`,
    },
  },
  cell: {
    "padding": "1rem",
    "display": "flex",
    "alignItems": "center",
    "borderBottom": `${size(1)} solid ${palette.gray[200]}`,

    "borderRight": `${size(1)} solid ${palette.gray[200]}`,
    "&:first-of-type": {
      borderLeft: `${size(1)} solid ${palette.gray[200]}`,
    },
  },
  cellInfo: {
    marginBottom: spacing("xs"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  iconWrapper: {
    marginRight: spacing("md"),
  },
  label: {
    color: colors.primary,
    fontWeight: typography.fontWeights.medium,
  },
  value: {
    wordWrap: "break-word",
    wordBreak: "break-all",
    overflow: "hidden",
    color: palette.gray[600],
    fontWeight: typography.fontWeights.medium,
    fontSize: typography.label.fontSize,
  },
}));

interface TableRowProps {
  items: { icon?: React.ReactNode; label: string; value: string }[];
}

const TableRow = ({ items }: TableRowProps) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.row}>
      {items.map(({ icon, label, value }) => (
        <div key={label} className={cx(classes.cell)}>
          {icon && <div className={classes.iconWrapper}>{icon}</div>}
          <div className={classes.cellInfo}>
            <span className={classes.label}>{label}</span>
            <span className={classes.value}>{value || "No data"}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TableRow;
