import { HistoricalProfile as Profile } from "@coral/typings";
import AgeGenderAffinity from "~/user-profile/components/historical-profile/age-gender-affinity";
import FavoriteBrands from "~/user-profile/components/historical-profile/favorite-brands";
import SectionHeader from "~/user-profile/components/section-header";
import FavoriteCategoryPriceRanges from "~/user-profile/components/historical-profile/favorite-category-price-ranges";

interface Props {
  historicalProfile: Profile;
}

export default function HistoricalProfileComponent({ historicalProfile }: Props) {
  return (
    <div>
      <SectionHeader
        title="Historical user profile"
        description="The historical user profile offers an aggregated view of a user's long-term behavior, preferences, and interactions over time. This section provides insights into the user's age-gender affinity, favorite brands within each category, and preferred price ranges per category, helping to understand their broader shopping patterns and tendencies."
      />
      <AgeGenderAffinity items={historicalProfile?.ageGenders} />
      <FavoriteBrands items={historicalProfile?.favoriteCategoryBrands} />
      <FavoriteCategoryPriceRanges items={historicalProfile?.favoriteCategoryPriceRanges} />
    </div>
  );
}
